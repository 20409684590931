import { createSlice } from '@reduxjs/toolkit'

let mesNumero = null;
export const mesSlice = createSlice({
  name: 'mes',
  initialState: {
    value: 13,
    name:''
  },
  reducers: {
    
    mesSeleccionado: (state, action) => {

      switch(action.payload) {
        case 'Enero':
          mesNumero = 1
          break;
        case 'Febrero':
          mesNumero = 2
          break;
        case 'Marzo':
          mesNumero = 3
          break;
        case 'Abril':
          mesNumero = 4
          break;
        case 'Mayo':
          mesNumero = 5
          break;
        case 'Junio':
          mesNumero = 6
          break;
        case 'Julio':
          mesNumero = 7
          break;
        case 'Agosto':
          mesNumero = 8
          break;
        case 'Septiembre':
          mesNumero = 9
          break;
        case 'Octubre':
          mesNumero = 10
          break;
        case 'Noviembre':
          mesNumero = 11
          break;
        case 'Diciembre':
          mesNumero = 12
          break;
        default:
          console.log('La opción no es válida');
          mesNumero = 13
      }

      state.value = mesNumero
    },
    mesSeleccionadoNombre: (state, action) => {
      state.name = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { mesSeleccionado,  mesSeleccionadoNombre} = mesSlice.actions

export default mesSlice.reducer