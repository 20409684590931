/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { StyledEngineProvider } from '@mui/material/styles';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { useApiFetch } from "hooks/useApiFetch";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { brandSelected } from "layouts/store/brandInventorySlice";
import { lineSelected } from "layouts/store/lineInventorySlice";



const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'

function Dashboard() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(brandSelected('A'))
    dispatch(lineSelected('B'))
  }, [dispatch]);

  const anioSeleccionado = useSelector((state) => state.anio.value);
  const mesSeleccionado = useSelector((state) => state.mes.value);
  const mesSeleccionadoNombre = useSelector((state) => state.mes.name);
  const MarcaSeleccionada = useSelector((state) => state.brand.value);
  const lineaSeleccionada = useSelector((state) => state.line.value);


  const { get } = useApiFetch();
  const [chartData, setChartData] = useState([]);
  const [chartDataAnterior, setChartDataAnterior] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [marcasLineas, setMarcasLineas] = useState([]);


  useEffect(() => {
  
    const fetchData = async () => {
      try {

        let responseApi;
        responseApi = await get(`/api/Boards/GetAllSalesForMonth?anio=${anioSeleccionado}&mes=${mesSeleccionado}&marca=${MarcaSeleccionada}&linea=${lineaSeleccionada}&key=${key}`)
        setChartData(responseApi);
        //console.log(responseApi,'resp actualizada');

        responseApi = await get(`/api/Boards/GetAllSalesForMonth?anio=${anioSeleccionado-1}&mes=${mesSeleccionado}&marca=${MarcaSeleccionada}&linea=${lineaSeleccionada}&key=${key}`)
        setChartDataAnterior(responseApi);
        // console.log(responseApi,'resp anterior');

        responseApi = await get(`/api/Boards/GetTotalVehiculo?anio=${anioSeleccionado}&mes=${mesSeleccionado}&marca=${MarcaSeleccionada}&linea=${lineaSeleccionada}&key=${key}`)
        setVehiculos(responseApi)
        //console.log(responseApi,'resp vehiculo');

        responseApi = await get(`/api/Boards/GetTotalMarca?anio=${anioSeleccionado}&mes=${mesSeleccionado}&marca=${MarcaSeleccionada}&key=${key}`)
        setMarcas(responseApi)
        //console.log(responseApi,'resp marca');

        responseApi = await get(`/api/Boards/GetAllSalesGeneral?anio=${anioSeleccionado}&mes=${mesSeleccionado}&key=${key}`)
        setMarcasLineas(responseApi)
        //console.log(responseApi,'resp marca-linea');

      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
  }, [anioSeleccionado, mesSeleccionado, MarcaSeleccionada, lineaSeleccionada]);

let utilidad;
let venta;
const opciones = { useGrouping: true, maximumFractionDigits: 0}; // Usa agrupamiento para separadores de miles
const mes = chartData.map(item => item.MesTransaccion) 
const data = chartData.map(item => Math.round(item.Total/1000000))

if (mesSeleccionadoNombre === ''){
  utilidad = chartData.map(item => item.Utilidad)
  venta = chartData.map(item => item.Total/1000000)
}else{

  utilidad = chartData.filter(item => item.MesTransaccion === mesSeleccionadoNombre).map(item => item.Utilidad);
  venta = chartData.filter(item => item.MesTransaccion === mesSeleccionadoNombre).map(item => item.Total/1000000);
}

const dataAnterior = chartDataAnterior.map(item => Math.round(item.Total/1000000))

//const numeroFormateadoAnterior = dataAnterior.toLocaleString('es-ES', opciones);
//console.log(numeroFormateado); // Output: "1.000.000"


const cantidadVehiculo = vehiculos.map(item => item.Total_vehiculos)

const listaMarcas = marcas.map(item => item.Marca)
const totalxMarca = marcas.map(item => item.Total/1000000)


const m = mes.length  > 0  ? mes:["Enero"]
const d = data.length > 0 ? data:[20]
const da = dataAnterior.length > 0 ? dataAnterior:[20]
const u = utilidad.length > 0 ? utilidad:[5]
const c = cantidadVehiculo.length > 0 ? cantidadVehiculo:[0]
const ma = listaMarcas.length  > 0  ? listaMarcas:["MARCA"]
const t = totalxMarca.length > 0 ? totalxMarca:[0]


const sales = {
  labels: m,
  datasets: [{
    label: anioSeleccionado,
    data: d,
  },
  {
    label: anioSeleccionado-1,
    data: da,
  }],
};


const totalSales = venta.reduce((total, valor) => total + valor, 0);
const totalSalesM = Math.round(totalSales)

const totalUtilidad = u.reduce((total, valor) => total + valor, 0);
const totalUtilidadM = Math.round(totalUtilidad/1000000)
//const totalUtilidadM = Math.round(totalUtilidad/1000000)

const sumaFormateada = totalSalesM.toLocaleString('es-CO', {
  style: 'currency',
  currency: 'COP' ,
  minimumFractionDigits: 0
});

const utilidadFormateada = totalUtilidadM.toLocaleString('es-CO', {
  style: 'currency',
  currency: 'COP' ,
  minimumFractionDigits: 0
});

const chartBar= {
  labels: ma,
  datasets: { label: "Sales", data: t },
};

// MARCA -LÍNEA MÁS VENDIDA

const maLi = marcasLineas.length  > 0  ? marcasLineas:[{"MARCA":'A',"Linea":"B"}]
//const objetoMasAlto = maLi.reduce((max, obj) => obj.Total > max.Total ? obj : max, maLi[0]);
const marcaYLinea =  maLi[0].Marca +" "+ maLi[0].Linea;





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Vehículos"
                count={c}
                // percentage={{
                //   color: "success",
                //   amount: "+55%",
                //   label: "que el mes pasado",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Venta"
                count={`${sumaFormateada} M`}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last month",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Utilidad"
                count={`${utilidadFormateada} M`}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Vehículo más vendido"
                count={marcaYLinea}
                // percentage={{
                //   color: "success",
                //   amount: "30%",
                //   label: "",
                // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Ventas por Mes vs Año anterior"
                  // description={
                  //   <>
                  //     (<strong>+15%</strong>) increase in today sales.
                  //   </>
                  // }
                  // date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Ventas por Marca"
                  // description="Last Campaign Performance"
                  // date="campaign sent 2 days ago"
                  chart={chartBar}
                />
              </MDBox>
            </Grid>
           
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid> */}
            
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
              {/* <StyledEngineProvider injectFirst>
                <Demo />
              </StyledEngineProvider> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
