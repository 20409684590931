/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import { useApiFetch } from "hooks/useApiFetch";
import { useEffect, useState } from "react";

const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'
function OrdersOverview() {

  const [data, setData] = useState([{
    Vendedor: "DIEGO ALEXANDER CASTAÑO BETANCUR",
    Marca: "CHEVROLET",
    Linea: "ONIX",
    Monto: 56000000,
    FechaVenta: "2024-03-13 13:29:43.200"
  }]);
  const { get } = useApiFetch();

  useEffect(() => {
  
    const fetchData = async () => {
      try {
        const responseApi = await get(`/api/Boards/GetUltimaVenta?key=${key}`)
        setData(responseApi);
        // console.log(responseApi,'resp actualizada ultima venta');

      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
}, []);


  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Última Venta
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>schedule</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
            {data[0].FechaVenta}
            </MDTypography>{" "}
            
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="success"
          icon="attach_money"
          title={ data[0].Monto.toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP' ,
            minimumFractionDigits: 0
          })}
          dateTime="Valor"
        />
        <TimelineItem
          color="error"
          icon="toys"
          title={data[0].Marca}
          dateTime="Marca"
        />
        <TimelineItem
          color="info"
          icon="drive_eta"
          title={data[0].Linea}
          dateTime="Línea"
        />
        <TimelineItem
          color="warning"
          icon="person"
          title={data[0].Vendedor}
          dateTime="Asesor"
          lastItem
        />
        {/* <TimelineItem
          color="primary"
          icon="vpn_key"
          title="New card added for order #4395133"
          dateTime="18 DEC 4:54 AM"
          lastItem
        /> */}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
