import { createSlice } from '@reduxjs/toolkit'

export const anioSlice = createSlice({
  name: 'anio',
  initialState: {
    value: 2024,
  },
  reducers: {
    
    anioSeleccionado: (state, action) => {

      if (isNaN(action.payload)){
        state.value = 2024
      } else{
        state.value = action.payload
      } 
      
    },
  },
})

// Action creators are generated for each case reducer function
export const { anioSeleccionado } = anioSlice.actions

export default anioSlice.reducer