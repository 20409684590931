// store.js
import { configureStore } from '@reduxjs/toolkit'

import anioReducer  from './anioSlice'
import mesReducer from './mesSlice'
import brandReducer from './brandSlice'
import brandInventoryReducer from './brandInventorySlice'
import lineReducer from './lineSlice'
import lineInventoryReducer from './lineInventorySlice'

export default configureStore({
  reducer: {
    anio: anioReducer,
    mes: mesReducer,
    brand: brandReducer,
    line: lineReducer,
    brandInventory: brandInventoryReducer,
    lineInventory: lineInventoryReducer
  },
})
