/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";

import logoRenault from "assets/images/small-logos/renault.svg";
import logoMazda from "assets/images/small-logos/logomazda.svg";
import logoSuzuki from "assets/images/small-logos/suzuki.svg";
import logoChevrolet from "assets/images/small-logos/chevrolet.svg";
import logoVolkswagen from "assets/images/small-logos/volkswagen.svg";
import logoNissan from "assets/images/small-logos/nissan.svg";
import logoToyota from "assets/images/small-logos/toyota2.svg";
import logoKia from "assets/images/small-logos/kia.svg";
import logoFord from "assets/images/small-logos/ford.svg";

import logoAudi from "assets/images/small-logos/renault.svg";
import logoBajaj from "assets/images/small-logos/logomazda.svg";
import logoBmw from "assets/images/small-logos/suzuki.svg";
import logoByd from "assets/images/small-logos/chevrolet.svg";
import logoCherry from "assets/images/small-logos/volkswagen.svg";
import logoCitroen from "assets/images/small-logos/nissan.svg";
import logoDaihatsu from "assets/images/small-logos/toyota2.svg";
import logoDodge from "assets/images/small-logos/kia.svg";
import logoFiat from "assets/images/small-logos/ford.svg";

import logoGreatWall from "assets/images/small-logos/renault.svg";
import logoHonda from "assets/images/small-logos/logomazda.svg";
import logoHondaMotos from "assets/images/small-logos/suzuki.svg";
import logoHiundai from "assets/images/small-logos/chevrolet.svg";
import logoKgm from "assets/images/small-logos/volkswagen.svg";
import logoLandRover from "assets/images/small-logos/nissan.svg";
import logoMistubishi from "assets/images/small-logos/toyota2.svg";
import logoPeugeot from "assets/images/small-logos/kia.svg";
import logoSkoda from "assets/images/small-logos/ford.svg";
import logoSsnagYong from "assets/images/small-logos/ford.svg";
import logoSubaru from "assets/images/small-logos/ford.svg";
import logoYamaha from "assets/images/small-logos/ford.svg";

import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useApiFetch } from "hooks/useApiFetch";

const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'
export default function data() {

  const anioSeleccionado = useSelector((state) => state.anio.value);
  const mesSeleccionado = useSelector((state) => state.mes.value);
  const mesSeleccionadoNombre = useSelector((state) => state.mes.name);
  const MarcaSeleccionada = useSelector((state) => state.brand.value);


  const [chartData, setChartData] = useState([]);
  const { get } = useApiFetch();

  useEffect(() => {
  
    const fetchData = async () => {
      try {

        let responseApi;
        responseApi = await get(`/api/Boards/GetAllSalesGeneral?anio=${anioSeleccionado}&mes=${mesSeleccionado}&marca=${MarcaSeleccionada}&key=${key}`)
        setChartData(responseApi);
        //console.log(responseApi,'resp tabla');

      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
}, [anioSeleccionado, mesSeleccionado, MarcaSeleccionada]);


  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  // Función para crear los datos de las filas dinámicamente
const generateRows = () => {
  const rows = [];

  // Iterar sobre cada elemento en 'data'
  chartData.forEach(item => {
    let logo;

    switch (item.Marca) {
      case "MAZDA":
        logo = logoMazda;
        break;
      case "RENAULT":
        logo = logoRenault;
        break;
      case "SUZUKI":
        logo = logoSuzuki;
        break;
      case "CHEVROLET":
        logo = logoChevrolet;
        break;
      case "VOLKSWAGEN":
        logo = logoVolkswagen;
        break;
      case "NISSAN":
        logo = logoNissan;
        break;
        case "TOYOTA":
        logo = logoToyota;
        break;
      case "KIA":
        logo = logoKia;
        break;
      case "FORD":
        logo = logoFord;
        break;
      case "BAJAJ":
        logo = logoBajaj;
        break;
      case "BYD":
        logo = logoByd;
        break;
      case "CHERY":
        logo = logoCherry;
        break;
        case "CITROËN":
        logo = logoCitroen;
        break;
      case "DAIHATSU":
        logo = logoDaihatsu;
        break;
      case "DODGE":
        logo = logoDodge;
        break;
      case "FIAT":
        logo = logoFiat;
        break;
      case "GREAT WALL":
        logo = logoGreatWall;
        break;
      case "HONDA":
        logo = logoHonda;
        break;
      case "HYUNDAI":
        logo = logoHiundai;
        break;
      case "MITSUBISHI":
        logo = logoMistubishi;
        break;
      case "PEUGEOT":
        logo = logoPeugeot;
        break;
      case "SKODA":
        logo = logoSkoda;
        break;
      case "SSANGYOUNG":
        logo = logoSsnagYong;
        break;
      case "SUBARU":
        logo = logoSubaru;
        break;
      case "YAMAHA":
        logo = logoYamaha;
        break;
      
     
      default:
        logo = "El número no es ni uno, ni dos, ni tres";
    }
   

      const row = {
         
          marcas: <Company image={logo} name={item.Marca} />,
          lineas: (
              <MDBox display="flex" py={1}>
                  {item.Linea}
              </MDBox>
          ),
          unidades: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.Unidades}
            </MDTypography>
          ),
          utilidad: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
                { item.Utilidad.toLocaleString('es-CO', {
                    style: 'currency',
                    currency: 'COP' ,
                    minimumFractionDigits: 0
                  })}
            </MDTypography>
              // <MDBox width="8rem" textAlign="left">
              //     <MDProgress value={100} color="success" variant="gradient" label={false} />
              // </MDBox>
          ),
      };

      // Agregar el objeto creado a 'rows'
      rows.push(row);
  });

  return rows;
};

  return {
    columns: [
      { Header: "marcas", accessor: "marcas", width: "45%", align: "left" },
      { Header: "líneas", accessor: "lineas", width: "10%", align: "left" },
      { Header: "unidades", accessor: "unidades", align: "center" },
      { Header: "utilidad", accessor: "utilidad", align: "center" },
    ],

    rows: generateRows()
    // rows: [
    //   {
    //     marcas: <Company image={logoRenault} name="RENAULT" />,
    //     lineas: (
    //       <MDBox display="flex" py={1}>
    //         {"CLIO"}
    //       </MDBox>
    //     ),
    //     utilidad: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         $14,000
    //       </MDTypography>
    //     ),
    //     completion: (
    //       <MDBox width="8rem" textAlign="left">
    //         <MDProgress value={60} color="info" variant="gradient" label={false} />
    //       </MDBox>
    //     ),
    //   },
    //   {
    //     marcas: <Company image={logoAtlassian} name="SUZUKI" />,
    //     lineas: (
    //       <MDBox display="flex" py={1}>
    //         {"SWIFT"}
    //       </MDBox>
    //     ),
    //     utilidad: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         $3,000
    //       </MDTypography>
    //     ),
    //     completion: (
    //       <MDBox width="8rem" textAlign="left">
    //         <MDProgress value={10} color="info" variant="gradient" label={false} />
    //       </MDBox>
    //     ),
    //   },
    //   {
    //     marcas: <Company image={logoSlack} name="CHEVROLET" />,
    //     lineas: (
    //       <MDBox display="flex" py={1}>
    //          {"OPTRA"}
    //       </MDBox>
    //     ),
    //     utilidad: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         Not set
    //       </MDTypography>
    //     ),
    //     completion: (
    //       <MDBox width="8rem" textAlign="left">
    //         <MDProgress value={100} color="success" variant="gradient" label={false} />
    //       </MDBox>
    //     ),
    //   },
    //   {
    //     marcas: <Company image={logoSpotify} name="MAZDA" />,
    //     lineas: (
    //       <MDBox display="flex" py={1}>
    //          {"CX-30"}
    //       </MDBox>
    //     ),
    //     utilidad: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         $20,500
    //       </MDTypography>
    //     ),
    //     completion: (
    //       <MDBox width="8rem" textAlign="left">
    //         <MDProgress value={100} color="success" variant="gradient" label={false} />
    //       </MDBox>
    //     ),
    //   },
    //   {
    //     marcas: <Company image={logoJira} name="VOLKSWAGEN" />,
    //     lineas: (
    //       <MDBox display="flex" py={1}>
    //          {"GOL COMFORTLINE"}
    //       </MDBox>
    //     ),
    //     utilidad: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         $500
    //       </MDTypography>
    //     ),
    //     completion: (
    //       <MDBox width="8rem" textAlign="left">
    //         <MDProgress value={25} color="info" variant="gradient" label={false} />
    //       </MDBox>
    //     ),
    //   },
    //   {
    //     marcas: <Company image={logoInvesion} name="TOYOTA" />,
    //     lineas: (
    //       <MDBox display="flex" py={1}>
    //         {"FORTUNER"}
    //       </MDBox>
    //     ),
    //     utilidad: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         $2,000
    //       </MDTypography>
    //     ),
    //     completion: (
    //       <MDBox width="8rem" textAlign="left">
    //         <MDProgress value={40} color="info" variant="gradient" label={false} />
    //       </MDBox>
    //     ),
    //   },
    // ],
    
  };
}
