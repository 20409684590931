import { createSlice } from '@reduxjs/toolkit'


export const lineInventorySlice = createSlice({
  name: 'lineInventory',
  initialState: {
    value: 'B',
  },
  reducers: {
    
    lineSelected: (state, action) => {
      state.value = action.payload
    },

  },
})

// Action creators are generated for each case reducer function
export const { lineSelected} = lineInventorySlice.actions

export default lineInventorySlice.reducer