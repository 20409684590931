import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {  useDispatch } from 'react-redux';
import { anioSeleccionado } from 'layouts/store/anioSlice';


export default function SelectVariants() {

  const dispatch = useDispatch()
  const [year, setYear] = React.useState('');

  const handleChange = (event) => { 
    setYear(event.target.value);
    dispatch(anioSeleccionado(parseInt(event.target.value)))
  };

  const anios = [2024,2023,2022]
  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Año</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={year}
          onChange={handleChange}
          label="Año"
          defaultValue='2024'
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem> 
          {anios.map( a => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
          
        </Select>
      </FormControl>
      
    </div>
  );
 
};
