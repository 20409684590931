import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {  useDispatch } from 'react-redux';
import { mesSeleccionado } from 'layouts/store/mesSlice'
import { mesSeleccionadoNombre } from 'layouts/store/mesSlice'

export default function SelectMonth() {

  const dispatch = useDispatch()

  const [month, setMonth] = React.useState('');

  const handleChange = (event) => {
    setMonth(event.target.value);
    dispatch(mesSeleccionado(event.target.value))
    dispatch(mesSeleccionadoNombre(event.target.value))
  };
  const meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Mes</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={month}
          onChange={handleChange}
          label="Mes"
          defaultValue='Enero'
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem> 
          {meses.map( a => (<MenuItem key={a} value={a}>{a}</MenuItem>))}

        </Select>
      </FormControl>
      
    </div>
  );
 
};
