import { createSlice } from '@reduxjs/toolkit'


export const lineSlice = createSlice({
  name: 'line',
  initialState: {
    value: 'B',
  },
  reducers: {
    
    lineSelected: (state, action) => {
      state.value = action.payload
    },

  },
})

// Action creators are generated for each case reducer function
export const { lineSelected} = lineSlice.actions

export default lineSlice.reducer