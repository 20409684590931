import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {  useDispatch } from 'react-redux';
import { brandSelected } from 'layouts/store/brandInventorySlice';
import { lineSelected } from 'layouts/store/lineInventorySlice';
import { useApiFetch } from 'hooks/useApiFetch';
import { useSelector } from 'react-redux';


const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'
export default function SelectBrandInventary() {

  const { get } = useApiFetch();
  const dispatch = useDispatch()

  const [data, setData] = React.useState([]);
  const [selectedBrand, setSelectedBrand] = React.useState('');
  const [selectedLine, setSelectedLine] = React.useState('');


  React.useEffect(() => {
  
    const fetchData = async () => {
      try {
        const responseApi = await get(`/api/Boards/GetAllSalesGeneral?key=${key}`)
        setData(responseApi);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
}, []);

  // Extraer marcas únicas
  const brands = Array.from(new Set(data.map(item => item.Marca)));

  // Filtrar líneas basadas en la marca seleccionada
  const lines = data.filter(item => item.Marca === selectedBrand).map(item => item.Linea);
  
  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    dispatch(brandSelected(event.target.value))
    setSelectedLine(''); // Limpiar la línea seleccionada cuando cambie la marca
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
    dispatch(lineSelected(event.target.value))
  };


  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Marca</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedBrand}
          onChange={handleBrandChange}
          label="Marca"
          defaultValue='KIA'
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem> 
          {brands.map( (brand, index) => (<MenuItem key={index} value={brand}>{brand}</MenuItem>))}
        
        </Select>
      </FormControl>

      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Línea</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedLine}
          onChange={handleLineChange}
          label="Línea"
          defaultValue='CERATO'
        >
          <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem> 
          {lines.map( (line, index) => (<MenuItem key={index} value={line}>{line}</MenuItem>))}
        
        </Select>
      </FormControl>
      
    </div>
  );
 
};
