import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useApiFetch } from "hooks/useApiFetch";
import { useDispatch } from 'react-redux';

import '../../../../../App.css';
import { TableFooter, TablePagination, useTheme } from '@mui/material';

import { anioSeleccionado } from 'layouts/store/anioSlice';
import { mesSeleccionado } from 'layouts/store/mesSlice';


const key = 'b8d89953f379f97616201f31e173bab6d7ea444f0b7adbef2ccc7c7ec8cb6a34'


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton 
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Marca}
        </TableCell>
        <TableCell >{row.Linea}</TableCell>
        <TableCell >{row.Modelo}</TableCell>
        <TableCell align="left">{row.Unidades}</TableCell>
        {/* <TableCell align="right">{row.protein}</TableCell>
        <TableCell align="right">{row.price}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1}}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <TableCell align="left">Fecha&nbsp;Ingreso</TableCell>
                    <TableCell align="left">Tipo&nbsp;Vehículo</TableCell>
                    <TableCell align="left">Placa</TableCell>
                    <TableCell align="left">Intermediario</TableCell>
                    <TableCell/>
                    <TableCell align="left">Propietario</TableCell>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell align="left">Celular</TableCell>
                    <TableCell align="left">Valor&nbsp;Real</TableCell>
                    <TableCell align="left">Días&nbsp;Inventario</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Detalle.map((detalleRow, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {detalleRow.fechaIngreso}
                      </TableCell>
                      <TableCell align="left">{detalleRow.tipoVehiculo}</TableCell>
                      <TableCell align="left">{detalleRow.placa}</TableCell>
                      <TableCell align="left" colSpan={2}>{detalleRow.intermediario} </TableCell>
                      <TableCell align="left" colSpan={8}>{detalleRow.propietario} </TableCell>
                      <TableCell align="left">{detalleRow.celular} </TableCell>
                      <TableCell align="left">{detalleRow.valorReal}  </TableCell>
                      <TableCell align="left">{detalleRow.diasInventario}</TableCell>
                      {/* <TableCell align="right">
                        {Math.round(detalleRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Linea: PropTypes.string.isRequired,
    Unidades: PropTypes.number.isRequired,
    Modelo: PropTypes.number.isRequired,
    Detalle: PropTypes.arrayOf(
      PropTypes.shape({
        fechaIngreso: PropTypes.string.isRequired,
        tipoVehiculo: PropTypes.string.isRequired,
        placa: PropTypes.string.isRequired,
        intermediario: PropTypes.string.isRequired,
        propietario: PropTypes.string.isRequired,
        celular: PropTypes.string.isRequired,
        diasInventario: PropTypes.number.isRequired,
      }),
    ).isRequired,
    Marca: PropTypes.string.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(anioSeleccionado(2024))
    dispatch(mesSeleccionado(''))
  }, [dispatch]);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };


  const MarcaSeleccionada = useSelector((state) => state.brandInventory.value);
  const lineaSeleccionada = useSelector((state) => state.lineInventory.value);

  const [data, setData] = useState([]);
  const { get } = useApiFetch();

  useEffect(() => {
  
    const fetchData = async () => {
      try {

        let responseApi;
        responseApi = await get(`/api/Boards/GetInventario?marca=${MarcaSeleccionada}&linea=${lineaSeleccionada}&key=${key}`)
        setData(responseApi);
        //console.log(responseApi,'resp tabla inventario');

      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  fetchData();
}, [MarcaSeleccionada,lineaSeleccionada]);

const groupedData = {};

// Iterar sobre los datos originales y agruparlos
data.forEach(item => {
  const key = `${item.Marca}-${item.Linea}-${item.Modelo}`;
  if (!groupedData[key]) {
      groupedData[key] = {
          Marca: item.Marca,
          Linea: item.Linea,
          Modelo: item.Modelo,
          Unidades: 0,
          Detalle: []
      };
  }
  groupedData[key].Unidades++;
  const fechaIngresoFormatted = item.FechaIngreso.split('T')[0];
  groupedData[key].Detalle.push({
      fechaIngreso: fechaIngresoFormatted,
      tipoVehiculo: item.TipoVehiculo,
      placa: item.Placa,
      intermediario: item.Intermediario,
      propietario: item.Propietario,
      celular: item.Celular,
      valorReal: item.ValorReal,
      diasInventario: item.DiasInventario
  });
});

const result = Object.values(groupedData);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - result.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

// Convertir el objeto agrupado en un array de objetos

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" sx={{ minWidth: 650 }} size="small">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell /> 
            <TableCell align="left">Marca</TableCell>
            <TableCell align="left">Línea</TableCell>
            <TableCell align="left">Modelo</TableCell>
            <TableCell align="left">Unidades</TableCell>
            {/* <TableCell align="right">Utilidad&nbsp;($)</TableCell>
            <TableCell align="right">Otra</TableCell> */}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : result
          ).map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={result.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'Registros por página',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}